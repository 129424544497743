<template>
  <div class="mt-3" :class="className" @click="$emit('showModalPreview')">
    <CCardGroup class="has-loading-overlay">
      <CCard class="p-1">
        <div style="background-color: #f5f5f0" v-if="organisation">
          <CRow>
            <div class="d-flex header-content">
              <div class="title-header p-3" style="width: 30%">
                <CRow class="justify-content-center">
                  <img
                    v-if="fileSource.logo"
                    :src="fileSource.logo"
                    class="rounded-circle ratio-1"
                    style="width: 60%"
                    @error="
                      $event.target.src = '/img/hospital.png';
                      $event.target.style = 'width:60%';
                    "
                    alt="logo"
                  />
                  <span
                    class="text-danger parent-info"
                    v-if="
                      !isCandidateOrPublicBoard &&
                      jobDetails &&
                      jobDetails.parent_org_info_required
                    "
                    style="color: danger"
                  >
                    <em
                      class="dot"
                      v-c-tooltip="{
                        content: 'Parent Branding Enabled',
                        appendToBody: true,
                      }"
                    ></em>
                  </span>
                </CRow>
                <div class="my-2">
                  <h5 class="text-primary text-center font-weight-bold">
                    {{ organisation }}
                  </h5>
                  <h6 class="label lblspan text-primary text-center">
                    <em class="fas fa-map-marker-alt"></em>&nbsp;
                    <span style="color: #00000099">{{ location }}{{ displayLocation ? ' | ' + displayLocation : '' }}</span>
                  </h6>
                </div>
              </div>
              <div class="p-2" style="width: 65%">
                <div class="title row">
                  <h4 class="text-left text-primary">{{ title }}</h4>
                </div>
                <CRow class="d-flex justify-content-between flex-row">
                  <CCol class="property">
                    <p class="text-primary">Candidate Type</p>
                    <p class="value">{{ candidateType }}</p>
                    <div
                      v-if="isNurse && jobDetails.location.country_id == 182"
                    >
                      <p class="value">{{ nhsBand }}</p>
                    </div>
                  </CCol>
                  <CCol class="property">
                    <p class="text-primary">Job Reference</p>
                    <p class="value">{{ displayJobReference }}</p>
                  </CCol>
                  <CCol class="property">
                    <p class="text-primary">Date Posted</p>
                    <p class="value">{{ date_created }}</p>
                  </CCol>
                  <CCol class="property">
                    <p class="text-primary">Candidate Level</p>
                    <div
                      class="value"
                      v-if="isTextWrapped(candidateLevel, 55)"
                      v-c-tooltip="{
                        content: candidateLevel,
                      }"
                    >
                      {{ candidateLevel.substring(0, 55) }}...
                    </div>
                    <div class="value" v-else>{{ candidateLevel }}</div>
                  </CCol>
                  <CCol class="property">
                    <p class="text-primary">Contract</p>
                    <p class="value">{{ contract }}</p>
                  </CCol>
                  <CCol class="property">
                    <p class="text-primary">Expires On</p>
                    <p class="value">
                      {{ expiryDate }} |
                      <span style="font-weight: 500">{{ expiryDays }}</span>
                    </p>
                  </CCol>
                </CRow>
              </div>
            </div>
          </CRow>
        </div>
        <div>
          <img
            v-if="fileSource.logo"
            :src="fileSource.image"
            style="width: 100% !important; max-height: 250px"
            @error="$event.target.src = '/img/job-image.jpg'"
            alt="Job Image"
          />
        </div>
        <div v-if="isNoSectionFound" class="p-1 mt-2">
          <p v-html="sectionTemplate"></p>
          <div v-show="preferred_in_advert" class="mt-3">
            <h4><strong>Additional Requirements:</strong></h4>
            <p class="mt-2" v-html="preferred_in_advert"></p>
          </div>
          <div v-show="benefits" class="mt-3">
            <h4><strong>Benefits Package:</strong></h4>
            <p class="mt-2" v-html="benefits"></p>
          </div>
          <div v-show="jobAdditionalInfo" class="mt-3">
            <h4><strong>Additional Information:</strong></h4>
            <p class="mt-2" v-html="jobAdditionalInfo"></p>
            <p
              v-show="sectionAdditionalInfo"
              v-html="sectionAdditionalInfo"
            ></p>
          </div>
        </div>
        <h5 class="text-center mt-2" v-else>Preview Not Available</h5>
      </CCard>
    </CCardGroup>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AddJob from "./AddJob";
import { deepClone, CONSTANT as CONST, appendBaseUrlWithLink } from "../../helpers/helper";

export default {
  name: "MicroPreview",
  mixins: [AddJob],
  props: [
    "jobDetails",
    "tempJob",
    "getImageUrl",
    "tempSectionsContent",
    "className",
    "tempBenefits",
  ],
  data() {
    return {
      appendBaseUrlWithLink
    }
  },
  computed: {
    ...mapGetters([
      "getOrganisationDocumentFile",
      "isCandidateSupplierFromAccessToken",
      "getRoleScopes",
    ]),
    title() {
      if (this.tempJob?.job_title) return this.tempJob.job_title;
      return this.jobDetails?.job_title || "--";
    },
    organisation() {
      if (this.tempJob?.organisation)
        return (
          this.tempJob.organisation?.label || this.tempJob.organisation?.name
        );
      return this.jobDetails?.organisation?.name;
    },
    candidateType() {
      if (this.tempJob?.candidateType) return this.tempJob.candidateType?.label;
      return this.jobDetails?.candidate_type?.candidate_type || "--";
    },
    candidateLevel() {
      if (this.tempJob?.level) {
        if (Array.isArray(this.tempJob?.level)) {
          return (
            this.tempJob?.level?.map((item) => item.label).join(", ") || "--"
          );
        } else return this.tempJob.level?.label || "--";
      }
      return this.jobDetails?.level_names?.join(", ") || "--";
    },
    sectionTemplate() {
      let data = deepClone(this.jobDetails);
      if (this.tempSectionsContent?.length) {
        data.job_desc_section_templates = this.tempSectionsContent;
      }
      return this.jobSections(data);
    },
    preferred_in_advert() {
      if (this.tempJob?.preferred_in_advert) {
        let data = "";
        let jobData = this.tempJob;
        jobData?.preferred_in_advert?.forEach((val) => {
          switch (val) {
            case "special_interests":
              let special_interests =
                jobData?.special_interest_ids
                  ?.map(({ label }) => label)
                  .join(", ") || false;
              special_interests =
                jobData?.candidate_type_id != CONST.AHP
                  ? special_interests
                  : null;
              if (special_interests)
                data += `<li>Special Interest in ${special_interests} preferred</li>`;
              break;
            case "minimum_years_of_experience":
              if (jobData.min_yrs_of_exp)
                data += `<li>Minimum ${jobData.min_yrs_of_exp} Years of Experience preferred</li>`;
              break;
            case "languages":
              let languages =
                jobData.language_ids?.map(({ label }) => label).join(", ") ||
                false;
              if (languages)
                data += `<li>${languages} Language(s) preferred</li>`;
              break;
            case "local_license_or_eligibility":
              let license = jobData.regional_license;
              license =
                license != "n/a"
                  ? license[0].toUpperCase() + license.substr(1)
                  : "";
              if (license) data += `<li>Local License ${license}</li>`;
              break;
            case "nationality":
              let nationalities =
                jobData.pref_nationality
                  ?.filter((filter) => filter.code)
                  ?.map(({ label }) => label)
                  .join(", ") || false;
              if (nationalities) data += `<li>${nationalities} preferred</li>`;
              break;
            case "gender":
              if (jobData.gender_id)
                data += `<li>${
                  jobData.gender_id == 1 ? "Male" : "Female"
                } preferred due to patient base</li>`;
              break;
            case "age":
              if (jobData.maximum_age)
                data += `<li>Applicants under ${jobData.maximum_age} years preferred</li>`;
              break;

            default:
              return;
          }
        });
        return data ? `<ul>${data}</ul>` : "";
      }
      return this.additionalRequirements(this.jobDetails);
    },
    benefits() {
      if (this.tempBenefits?.length) return this.jobBenefits(this.tempBenefits);
      return this.jobBenefits(this.jobDetails?.benefits);
    },
    jobAdditionalInfo() {
      
      let addInfo = "",
        info = "";
      if (this.tempJob?.working_hours || this.tempJob?.working_days) {
        if (this.tempJob.full_time == "yes") addInfo += `<li>Full Time</li>`;
        else if (this.tempJob.full_time == "flexible")
          addInfo += `<li>Full Time (Flexible)</li>`;
        if (this.tempJob.part_time == "yes") addInfo += `<li>Part Time</li>`;
        else if (this.tempJob.part_time == "flexible")
          addInfo += `<li>Part Time (Flexible)</li>`;
        if (this.tempJob.permanent_work) addInfo += `<li>Permanent Work</li>`;
        if (this.tempJob.temporary_work) addInfo += `<li>Temporary Work</li>`;
        if (this.tempJob.working_hours?.detail_number)
          addInfo += `<li>${this.tempJob.working_hours?.detail_text}</li>`;
        if (this.tempJob.working_days?.detail_number)
          addInfo += `<li>${this.tempJob.working_days?.detail_text}</li>`;
        if (this.tempJob.call_on_radio && this.tempJob.call_on?.detail_number)
          addInfo += `<li>${this.tempJob.call_on?.detail_text}</li>`;
      } else if (this.jobDetails?.job_id) {
        if (this.jobDetails.full_time == "yes") addInfo += `<li>Full Time</li>`;
        if (this.jobDetails.part_time == "yes") addInfo += `<li>Part Time</li>`;
        if (this.jobDetails.permanent_work)
          addInfo += `<li>Permanent Work</li>`;
        if (this.jobDetails.temporary_work)
          addInfo += `<li>Temporary Work</li>`;
        if (this.jobDetails?.job_details?.length) {
          info = this.jobDetails.job_details.reduce((list, { detail_text }) => {
            return list + `<li>${detail_text}</li>`;
          }, "");
          addInfo += info;
        }
      }
      return addInfo ? `<ul>${addInfo}</ul> ` : "";
    },
    sectionAdditionalInfo() {
      let data = deepClone(this.jobDetails);
      if (this.tempSectionsContent) {
        data.job_desc_section_templates = this.tempSectionsContent;
      }
      return this.jobSectionAdditionalInfo(data);
    },
    isNoSectionFound() {
      return (
        this.sectionTemplate ||
        this.preferred_in_advert ||
        this.benefits ||
        this.jobAdditionalInfo ||
        this.sectionAdditionalInfo
      );
    },
    jobPicture() {
      if (this.jobDetails?.job_picture)
        return this.jobDetails?.job_picture?.link_to_doc || "";
      if (this.getJobPictures?.length) return this.getJobPictures[0].url;
    },
    location() {
      return (
        this.jobDetails?.location?.location ||
        this.tempJob.location_id?.label ||
        "--"
      );
    },
     displayLocation() {
      return this.jobDetails?.display_location;
    },
    jobReference() {
      return this.jobDetails?.job_id;
    },
    displayJobReference() {
      return this.jobDetails?.display_uid || "--";
    },
    contract() {
      if (this.jobDetails) {
        const { permanent_work, temporary_work } = this.jobDetails;
        if (permanent_work && temporary_work) return "Permanent; Temporary";
        else if (permanent_work) return "Permanent";
        else if (temporary_work) return "Temporary";
      }
      return "--";
    },
    date_created() {
      if (!this.jobDetails?.date_created) {
        return this.$moment().format("DD MMM YYYY");
      }
      return this.formatDate(this.jobDetails?.created_on);
    },
    expiryDate() {
      return this.formatDate(this.jobDetails?.expiry_review_date);
    },
    expiryDays() {
      let days = "--";
      if (this.jobDetails?.expiry_review_date) {
        let diff = this.$moment(this.jobDetails?.expiry_review_date).diff(
          this.$moment(),
          "days"
        );
        days = diff < 0 ? "Expired" : `${diff} Days`;
      }
      return days;
    },
    isParentReBranding() {
      return this.jobDetails?.parent_org_info_required || false;
    },
    isCandidateOrPublicBoard() {
      return (
        !this.getRoleScopes.length ||
        ["system_candidate", "customer_candidate"].includes(
          this.getRoleScopes[0]
        )
      );
    },
    isNurse() {
      return this.jobDetails?.candidate_type_id == 2 ||
        this.jobDetails?.candidate_type?.candidate_type_id == 2
        ? true
        : false;
    },
    nhsBand() {
      let data = [];
      let band = this.jobDetails?.current_nhs_bands
        ? this.jobDetails?.current_nhs_bands
        : [];
      for (var i = 0; i < band.length; i++) {
        data.push(band[i].band_name);
      }
      return data.join("/ ") || null;
    },
    fileSource() {
      let job = this.jobDetails;
      let organisation = {};
      organisation = job?.organisation;
      if (this.tempJob?.organisation) {
        organisation =
          this.tempJob?.organisation?.organisation ||
          this.tempJob?.organisation;
      }
      let image = organisation?.default_job_banner_url;
      let logo = organisation?.logo_url;
      if (this.jobPicture) image = appendBaseUrlWithLink(this.jobPicture);
      // if (this.getImageUrl) image = this.getImageUrl;
      if (this.isParentReBranding || this.isCandidateSupplierFromAccessToken) {
        if (job?.parent_org_info?.length) {
          return {
            logo: appendBaseUrlWithLink(job?.parent_org_info[0]?.logo_url) || "",
            image: appendBaseUrlWithLink(job?.parent_org_info[0]?.default_job_banner_url) || "",
          };
        }
      }
      if (organisation?.organisation_id) {
        logo = logo || "/img/hospital.png";
        image = image || "/img/job-image.jpg";
      }
      return {
        logo: appendBaseUrlWithLink(logo) || "",
        image: image || "",
      };
    },
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment(date).format("DD MMM YYYY") : "--";
    },
    isTextWrapped(data, length) {
      return data?.length > length || false;
    },
  },
};
</script>
<style lang="scss" scoped>
.micro-preview {
  background: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  width: 100%;
  /* height: 400px; */
  text-align: left;
  overflow: hidden;
  position: relative;
  font-size: 8px !important;
}
.micro-preview h4 {
  font-size: 10px !important;
  margin-top: 0px !important;
}
.micro-preview h4 strong {
  font-size: 10px !important;
}
.micro-preview h5 {
  font-size: 9px !important;
}
.micro-preview h6 {
  font-size: 8px !important;
}
.micro-preview .property p {
  margin-bottom: 8px;
}
.micro-preview .title {
  margin-left: 0px;
}
.property {
  font-weight: bold !important;
}
.value {
  font-weight: 400 !important;
}
.org-logo {
  height: 40%;
  width: 40%;
}
@media all and (max-width: 991px) {
  .macro-preview .property {
    padding: 0px;
    min-width: 100px;
  }
}
@media all and (min-width: 992px) {
  .macro-preview .property {
    padding: 0px;
    min-width: 150px;
  }
  .micro-preview .property {
    padding: 0px;
    min-width: 33%;
  }
  .title {
    margin-left: -15px !important;
  }
}
@media all and (max-width: 506px) {
  .title-header {
    max-width: 100%;
  }
  .org-logo {
    height: 25%;
    width: 25%;
  }
}
@media all and (max-width: 356px) {
  .property {
    text-align: center;
  }
}
.ratio-1 {
  aspect-ratio: 1;
}
.parent-info {
  position: absolute;
  margin-left: 5.8rem;
  margin-top: 1.05rem;
}
.micro-preview .dot {
  height: 10px;
  width: 10px;
  background-color: $font-theme-color;
  border-radius: 60%;
  display: inline-block;
  position: absolute;
  z-index: 1000;
  top: -10px;
  right: 15px;
}
.macro-preview .dot {
  height: 10px;
  width: 10px;
  background-color: $font-theme-color;
  border-radius: 60%;
  display: inline-block;
  position: absolute;
  z-index: 1000;
  top: -5px;
  right: -5px;
}
</style>
