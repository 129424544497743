<template>
  <div class="create add-job pb-4">
    <LoadingOverlay v-if="!isFetchingJobData" />
    <ValidationObserver ref="newPageFive" v-slot="{ handleSubmit }">
      <form id="create" @submit.prevent="handleSubmit()">
        <CCardGroup class="has-loading-overlay">
          <CCard v-if="jobDesc">
            <CCardBody class="p-3">
              <div class="d-flex justify-content-between">
                <h5 class="main-title">Publish Job</h5>
              </div>

              <CRow class="justify-content-center">
                <CCol md="10">
                  <CRow class="row mb-3 p-3">
                    <JobDetailPreview
                      :jobDetails="jobs"
                      :advertDocuments="jobDocuments"
                    ></JobDetailPreview>
                  </CRow>
                </CCol>
              </CRow>
              <div class="mb-2">
                <h5 class="text-primary">
                  Internal: Attach document associated to this job
                </h5>
                <InternalInfo
                  :profile="jobById"
                  :isMobile="isMobile"
                  :isAccessingFromAddJob="true"
                  :isEditable="true"
                />
              </div>
              <div>
                <h5 class="text-primary">Configure your Advert</h5>
                <CRow>
                  <CCol md="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12">
                        Expiry Review Date
                      </label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <DatePicker
                            name="expiry_review_date"
                            :value="jobs.expiry_review_date"
                            @input="handleDatePickerChange"
                            :showFromTmw="true"
                            :error="errors[0]"
                            format="DD-MM-YYYY"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="6" class="mb-3">
                    <CRow class="row">
                      <label class="col-lg-12 col-md-12">
                        Advertising Location
                      </label>
                      <div class="col infoicon-multi-select">
                        <ValidationProvider>
                          <Select
                            name="advertisement_location"
                            :value="jobs.advertisement_location"
                            @input="handleChangeSelect"
                            :options="
                              options && options['advertisement_location']
                                ? options['advertisement_location']
                                : []
                            "
                            :taggable="false"
                            :multiple="true"
                            :clearable="true"
                          />
                        </ValidationProvider>
                      </div>
                      <div class="position-relative multi-select-icon-div">
                        <CIcon
                          name="cil-info"
                          class="hinticon-multi-select"
                          v-c-tooltip="'Select one or more'"
                        />
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="6" class="mb-3">
                    <CRow class="registration">
                      <label class="pt-1 col-7">
                        Private Job?
                        <em
                          class="fas fa-info-circle text-primary"
                          style="
                            vertical-align: super;
                            font-size: smaller;
                            cursor: pointer;
                          "
                          v-c-tooltip="{
                            content: `Private Jobs are for internal use only and are not Published to Job Boards!`,
                            appendToBody: true,
                          }"
                        />
                      </label>
                      <CSwitch
                        name="is_private_job"
                        class="mt-1"
                        color="success"
                        :checked="jobs.is_private_job"
                        shape="pill"
                        variant="3d"
                        @change.native="
                          handleChangeCheck('is_private_job', $event)
                        "
                      />
                    </CRow>
                  </CCol>
                  <CCol
                    md="6"
                    class="mb-3"
                    v-if="job_has_relationship_with_super_hospital"
                  >
                    <CRow class="registration">
                      <label class="pt-1 col-7 d-flex">
                        Publish in TalentFind World?
                        <span
                          class="tfw-icon ml-2"
                          v-c-tooltip="{
                            content:
                              'This option publishes the job into TalentFind World Public Job Board',
                            placement: 'left',
                          }"
                        ></span>
                      </label>
                      <CSwitch
                        name="publish_in_super_hospital"
                        class="mt-1"
                        color="success"
                        :checked="jobs.publish_in_super_hospital"
                        shape="pill"
                        variant="3d"
                        @change.native="
                          handleChangeCheck('publish_in_super_hospital', $event)
                        "
                      />
                    </CRow>
                  </CCol>
                  <CCol
                    md="6"
                    class="mb-3"
                    v-if="jobs.organisation && jobs.organisation.parent_org_id"
                  >
                    <CRow class="registration">
                      <label class="pt-1 col-7">
                        Inherit Parent Branding?
                        <em
                          class="fas fa-info-circle text-primary"
                          style="
                            vertical-align: super;
                            font-size: smaller;
                            cursor: pointer;
                          "
                          v-c-tooltip="{
                            content: `Only select this option if you need to display an alternative logo`,
                            appendToBody: true,
                          }"
                      /></label>
                      <CSwitch
                        name="parent_org_info_required"
                        class="mt-1"
                        color="success"
                        :checked="jobs.parent_org_info_required"
                        shape="pill"
                        variant="3d"
                        @change.native="
                          handleChangeCheck('parent_org_info_required', $event)
                        "
                      />
                    </CRow>
                  </CCol>
                </CRow>
              </div>
            </CCardBody>
          </CCard>
        </CCardGroup>

        <div
          class="
            row-sm
            my-2
            action-group
            d-flex
            justify-content-between
            flex-wrap
          "
        >
          <div class="btn-add-job-cancel">
            <button
              name="cancel"
              class="
                btn
                rounded-2
                px-5
                btn-lg
                mt-3
                float-left
                btn-outline-primary btn-lg
              "
              @click="$parent.goToJobBoard()"
            >
              Cancel
            </button>
          </div>
          <div class="btn-add-job-submit">
            <button
              type="button"
              name="Back"
              class="
                btn
                rounded-2
                px-5
                btn-lg
                mt-3
                float-left
                btn-outline-primary btn-lg
                mr-2
              "
              @click="onBack"
              :disabled="isLoading"
            >
              Back
            </button>

            <button
              name="next_page"
              class="btn rounded-2 btn-primary btn-lg mt-3 mr-2"
              @click="onSave('saveDraft')"
              :disabled="isLoading"
            >
              Save as Draft
            </button>
            <button
              v-if="jobs.is_private_job"
              name="next_page"
              class="btn rounded-2 btn-primary btn-lg mt-3"
              @click="onSave('directPublish')"
              :disabled="isLoading"
            >
              Save & Publish
            </button>
            <button
              v-else
              name="next_page"
              class="btn rounded-2 btn-primary btn-lg mt-3"
              @click="onSave('savePublish')"
              :disabled="isLoading"
            >
              {{
                isSendForApprovalRequired
                  ? "Send For Approval"
                  : "Save & Publish"
              }}
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>

    <CModal
      v-if="!hasJobAuthorizerRole"
      id="Preview Job"
      centered:true
      :show.sync="publishModal"
      size="lg"
      color="primary"
    >
      <template #header-wrapper>
        <header class="modal-header" style="background: #dd3651; color: #fff">
          <h5 class="modal-title">Approval</h5>
          <button
            @click="onCancelPublish"
            type="button"
            aria-label="Close"
            class="close"
          >
            x
          </button>
        </header>
      </template>
      <template #body-wrapper>
        <ValidationObserver
          ref="action_modal"
          v-slot="{ onPublish }"
          v-if="job_authorizer_ids.length || isJobSupplier"
        >
          <form id="action" @submit.prevent="onPublish()">
            <CRow class="m-2">
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="required col-lg-12 col-md-12">
                    Alert Set For
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <DatePicker
                        name="publish_request_expiry_date"
                        :value="jobs.publish_request_expiry_date"
                        @input="handleDatePickerChange"
                        :showFromTmw="true"
                        :error="errors[0]"
                        format="DD-MM-YYYY"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label name="lbl_urgent" class="col-lg-12 col-md-12"
                    >Urgent Approval?</label
                  >
                  <CCol md="12">
                    <RadioButton
                      name="publish_request_is_urgent"
                      :value="jobs.publish_request_is_urgent"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleChangeRadio"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="12" class="mb-3">
                <CRow class="row">
                  <div class="col-lg-12 col-md-12 d-flex pb-2">
                    <div>
                      <label class="text-nowrap pr-3">Comment</label>
                    </div>
                    <div class="w-100" v-if="options['commentsOptions'].length">
                      <Select
                        name="customComments"
                        :value="jobs.customComments"
                        @input="handleChangeSelectCustom"
                        :options="
                          options && options['commentsOptions']
                            ? options['commentsOptions']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextareaInput
                        name="publish_request_comments"
                        :value="jobs.publish_request_comments"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </template>
      <template #footer-wrapper>
        <div class="d-flex justify-content-between py-3 flex-wrap mx-2">
          <div class="btn-add-job-cancel">
            <button
              name="cancel"
              class="
                btn
                rounded-2
                px-5
                btn-lg
                mt-3
                float-left
                btn-outline-primary btn-lg
                mr-2
              "
              @click="onCancelPublish"
            >
              Cancel
            </button>
          </div>
          <div class="btn-add-job-submit">
            <button
              v-if="job_authorizer_ids.length"
              name="publish_page"
              class="btn rounded-2 btn-primary btn-lg mt-3 px-4"
              @click="onPublish()"
              :disabled="!jobs.publish_request_comments"
            >
              Confirm
            </button>
            <button
              v-else
              name="publish_page"
              class="btn rounded-2 btn-primary btn-lg mt-3 px-4"
              @click="onPublish()"
            >
              Confirm
            </button>
          </div>
        </div>
      </template>
    </CModal>

    <CModal
      v-if="hasJobAuthorizerRole"
      id="Preview Job"
      centered:true
      :show.sync="publishModal"
      size="lg"
      color="primary"
    >
      <template #header-wrapper>
        <header class="modal-header" style="background: #dd3651; color: #fff">
          <h5 class="modal-title">Notification</h5>
          <button
            @click="onCancelPublish"
            type="button"
            aria-label="Close"
            class="close"
          >
            x
          </button>
        </header>
      </template>
      <template #body-wrapper>
        <ValidationObserver
          ref="action_modal"
          v-slot="{ onPublish }"
          v-if="job_authorizer_ids.length || isJobSupplier"
        >
          <form id="action" @submit.prevent="onPublish()">
            <CRow class="m-2">
              <CCol md="12" class="mb-3">
                <CRow class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div>
                      <label class="text-nowrap pr-3">Comments</label>
                    </div>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextareaInput
                        name="publish_request_comments"
                        :value="jobs.publish_request_comments"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </template>
      <template #footer-wrapper>
        <h6 class="ml-4 mb-0" style="color: red">
          Do you want to keep this job Pending?<br />
          A pending job remains private.<br />
          Pending or published jobs will enable the automatic candidate matching.<br />
          Draft jobs do not show matched candidates.
        </h6>
        <div class="d-flex justify-content-between py-3 flex-wrap mx-2">
          <div class="btn-add-job-cancel">
            <button
              name="cancel"
              class="
                btn
                rounded-2
                px-5
                btn-lg
                mt-3
                float-left
                btn-outline-primary btn-lg
                mr-2
              "
              @click="onCancelPublish"
            >
              Cancel
            </button>
          </div>
          <div class="btn-add-job-submit">
            <button
              v-if="job_authorizer_ids.length"
              name="publish_page"
              class="btn rounded-2 btn-primary btn-lg mt-3 px-4"
              @click="onKeepPending()"
            >
              Keep Pending
            </button>
            <button
              v-if="job_authorizer_ids.length"
              name="publish_page"
              class="btn rounded-2 btn-primary btn-lg mt-3 ml-3 px-4"
              @click="onPublish()"
            >
              Publish Job
            </button>
          </div>
        </div>
      </template>
    </CModal>

    <CModal
      id="Save as Draft"
      title="Save as Draft"
      centered:true
      color="primary"
      :show.sync="draftModal"
    >
      <p>Please confirm you would like to save this job as a Draft Job?</p>
      <template #footer>
        <div class="d-flex justify-content-end m-0">
          <button @click="onCancelDraft" class="btn btn-secondary px-3 mr-3">
            No
          </button>
          <button @click="onDraftSave" class="btn btn-primary px-3">Yes</button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import m from "moment";
import JobDetailPreview from "@/components/JobDetailPreview/JobDetailPreview.vue";
import PreviewModal from "@/components/reusable/PreviewModal";
import AddJob from "./AddJob";
import isJobSupplier from "@/mixins/isJobSupplier";
import InternalInfo from "@/containers/JobsDetailedView/JobDetailSection/InternalInfo";
import { CONSTANT as CONST } from "@/helpers/helper";

export default {
  components: {
    JobDetailPreview,
    PreviewModal,
    InternalInfo,
  },
  mixins: [AddJob, isJobSupplier],
  data() {
    return {
      jobs: {},
      payload: {
        step: 4,
      },
      publishModal: false,
      draftModal: false,
      isLoading: false,
      audits: [],
      isMobile: false,
    };
  },
  computed: {
    isFetchingJobData() {
      if (Object.keys(this.jobById).length) {
        let jobData = this.jobById;
        this.jobs = {
          ...this.jobById,
          expiry_review_date: jobData?.expiry_review_date
            ? this.isBefore(new Date(jobData?.expiry_review_date))
            : null,
          advertisement_location: jobData?.advertisement_location?.length
            ? this.getAdLocationForSelectedID(jobData?.advertisement_location)
            : jobData?.advert_description
            ? null
            : [
                {
                  code: "hospital_job_board",
                  label: `${
                    this.isUKMainAgency || this.isJobSupplierFromAccessToken
                      ? "Job Board – Local"
                      : "Hospital Job Board"
                  }`,
                },
              ],
          advert_description: jobData.advert_description,
          publish_request_comments: "",
          publish_request_is_urgent: false,
          publish_request_expiry_date: null,
          parent_org_info_required:
            (jobData?.parent_org_info_required == null &&
              this.childHasNoAcronym &&
              jobData?.organisation?.organisation_type_id != 3) ||
            this.isJobSupplier
              ? true
              : jobData?.parent_org_info_required,
          parent_org_info: jobData?.organisation?.parent_organisation,
        };
        this.audits = jobData.approval_audits
          ?.sort(
            (a, b) => new Date(b.initiated_date) - new Date(a.initiated_date)
          )
          .filter((val) => val.status_id == 28 && val.sub_status_id == 72)[0];
      }
      return true;
    },
    _is_last_action_is_published() {
      return (
        this.jobById?.approval_audits?.[0]?.status_id == CONST.JOB_ACTIVE_STATUS
      );
    },
    job_authorizer_ids() {
      return (
        this.jobById.job_users
          ?.filter((val) => {
            return val?.customer_user?.customer_user_type_id == 4;
          })
          ?.map((val) => val.customer_user?.user_id) || []
      );
    },
    options() {
      return {
        advertisement_location: this.customAdvertisementLocation || [],
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        commentsOptions: this.actionComments || [],
      };
    },
    customAdvertisementLocation() {
      const res = this.getEnumLabels("advertisementlocation") || [];
      if (this.isUKMainAgency || this.isJobSupplierFromAccessToken) {
        const pertempsValue = {
          hospital_job_board: "Job Board – Local",
        };
        return res
          .map((e) => {
            let opt = e;
            if (pertempsValue[e.code]) opt["label"] = pertempsValue[e.code];
            return opt;
          })
          .filter(
            ({ code }) => code != "social_media" && code != "group_job_board"
          );
      }
      return res;
    },
    jobDesc() {
      const { job_id } = this.jobById;
      if (job_id) {
        this.jobDescription = this.jobSections(this.jobById);
        // Additional Requirements
        if (this.additionalRequirements(this.jobById))
          this.jobDescription += `<h4 class="mt-3 mb-2"><strong>Additional Requirements</strong></h4>${this.additionalRequirements(
            this.jobById
          )}`;
        // Job Benefits
        if (this.jobBenefits(this.jobById?.benefits))
          this.jobDescription += `<h4 class="mt-3 mb-2"><strong>Benefits Package:</strong></h4>${this.jobBenefits(
            this.jobById?.benefits
          )}`;
        // Additional Information
        this.jobDescription += `<h4 class="mt-3"><strong>Additional Information:</strong></h4><ul class="mt-2 mb-1">`;
        // additional info - full time
        if (this.jobById.full_time != "no")
          this.jobDescription += `${
            this.jobById.full_time == "flexible"
              ? "<li>Full Time (Flexible)</li>"
              : "<li>Full Time</li>"
          }`;
        // additional info - part time
        if (this.jobById.part_time != "no")
          this.jobDescription += `${
            this.jobById.part_time == "flexible"
              ? "<li>Part Time (Flexible)</li>"
              : "<li>Part Time</li>"
          }`;
        // additional info - contract
        if (this.jobById.permanent_work)
          this.jobDescription += `<li>Permanent Work</li>`;
        if (this.jobById.temporary_work)
          this.jobDescription += `<li>Temporary Work</li>`;
        // job details like working hours, working days, working pattern
        if (this.jobById?.job_details?.length) {
          let AdditionalInfoDisp = this.jobById.job_details.reduce(
            (list, { detail_text }) => {
              return list + `<li>${detail_text}</li>`;
            },
            ""
          );
          this.jobDescription += AdditionalInfoDisp;
        }
        this.jobDescription += `</ul>`;
        // Section Additional Information
        if (this.jobSectionAdditionalInfo(this.jobById))
          this.jobDescription += this.jobSectionAdditionalInfo(this.jobById);
        Vue.set(this.jobs, "advert_description", this.jobDescription);
      }
      return true;
    },
    jobDocuments() {
      return this.jobDocumentActions?.filter((val) => val.add_to_advert) || [];
    },
    isSendForApprovalRequired() {
      if (this.isJobSupplier || this.job_authorizer_ids?.length) {
        if (
          (this._is_last_action_is_published &&
            !this.jobById.approval_required) ||
          this.job_authorizer_ids.includes(this.getUserId)
        ) {
          return false;
        }
      }
      return true;
    },
    hasJobAuthorizerRole() {
      return this.job_authorizer_ids.includes(this.getUserId);
    },
    job_has_relationship_with_super_hospital() {
      return this.jobById?.customer_organisation?.customer_organisation_relationship?.some(
        (val) => val.host_is_super_hospital
      );
    },
  },

  methods: {
    onStep(step) {
      this.$emit("step", step);
    },
    async onSave(action) {
      const isValid = await this.$refs.newPageFive.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (action === "saveDraft") {
        this.draftModal = true;
      } else if (action === "savePublish") {
        if (!this.isSendForApprovalRequired && !this.hasJobAuthorizerRole) {
          this.onPublish();
        } else {
          if (m(this.audits?.expiry_date, "YYYY-MM-DD").isBefore(m())) {
            this.audits.expiry_date = m().add(5, "days");
          }
          this.jobs.customComments = null;
          this.jobs.publish_request_comments = "";
          this.jobs.publish_request_is_urgent = this.audits?.is_urgent || false;
          this.jobs.publish_request_expiry_date = this.audits?.expiry_date
            ? new Date(this.audits?.expiry_date)
            : new Date(m().add(5, "days"));
          this.publishModal = true;
        }
      } else if (action === "directPublish") {
        this.updatePayload();
        this.updateJobSteps({
          ...this.payload,
          published: true,
          showToast: false,
        }).then((res) => {
          this.showToast({
            class: "bg-success text-white",
            message: "Published successfully",
          });
          this.$router.push(`/jobs-list/status/active?id=${res.data.job_id}`);
        });
      }
    },
    onCancelPublish() {
      this.publishModal = !this.publishModal;
    },
    onKeepPending() {
      this.payload.publish_request_keep_pending = true;
      this.onPublish();
    },
    async onPublish() {
      if (this.isSendForApprovalRequired) {
        const isValid = await this.$refs.action_modal.validate();
        if (!isValid) {
          this.showToast({
            class: "bg-danger text-white",
            message: "please fill mandatory fields!",
          });
          return;
        }
      }
      this.publishModal = false;
      this.isLoading = true;
      this.updatePayload();
      if (this.isSendForApprovalRequired) {
        this.payload = {
          ...this.payload,
          publish_request_is_urgent:
            this.jobs?.publish_request_is_urgent?.code ||
            this.jobs?.publish_request_is_urgent,
          publish_request_expiry_date: new Date(
            m(this.jobs?.publish_request_expiry_date).add(5.5, "hours")
          ).toISOString(),
        };
      }
      this.updateJobSteps({
        ...this.payload,
        published: true,
        showToast: false,
      }).then((res) => {
        this.showToast({
          class: "bg-success text-white",
          message: this.isSendForApprovalRequired
            ? "Job sent for Approval.."
            : "Published successfully",
        });
        this.isLoading = false;
        this.$router.push(`/jobs-list/status/active?id=${res.data.job_id}`);
      });
    },
    onDraftSave() {
      this.updatePayload();
      this.updateJobSteps({ ...this.payload, published: false }).then((res) => {
        this.draftModal = false;
        this.$router.push(`/jobs-list/status/active?id=${res.data.job_id}`);
      });
    },
    onBack() {
      this.isLoading = true;
      this.updatePayload();
      this.updateJobSteps({ ...this.payload }).then((res) => {
        this.draftModal = false;
        this.isLoading = false;
        this.onStep(3);
      });
    },
    updatePayload() {
      this.payload = {
        ...this.payload,
        job_id: this.jobById.job_id,
        customer_uid: this.jobById.customer_uid,
        organisation: this.jobById.organisation.organisation_id,
        advert_description: this.jobs?.advert_description,
        advertisement_location: this.jobs?.advertisement_location?.length
          ? this.jobs?.advertisement_location?.map((val) => val.code)
          : null,
        parent_org_info_required: this.jobs?.parent_org_info_required,
        publish_request_expiry_date: null,
      };
    },
    onCancelDraft() {
      this.draftModal = false;
    },
    initFetchDoc() {
      // Fetch customer document library
      this.fetchCustomerDocumentLib();
      const payload = {
        job_id: this.jobById.job_id,
        action_type: "job",
        candidate_uid__isnull: true,
        organisation_id: this.jobById.organisation_uid,
      };
      this.getJobDocumentAction(payload);
    },
    isBefore(date) {
      if (m().isAfter(m(date).format("YYYY-MM-DD"))) {
        return new Date(m().add(7, "days").startOf("day"));
      }
      return date;
    },
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    this.isMobile = isMobile;
    this.initFetchDoc();
    this.fetchCommentsfor_jobapproval();
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  border-radius: 4px !important;
}
@media all and (max-width: 1024px) {
  .org-logo {
    width: 100%;
  }
  .btn-add-job-cancel {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
  .btn-add-job-submit {
    width: 100%;
    .btn {
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>
